import React from "react"

import { graphql } from "gatsby"

import { PrismicBanners } from "../../components/Banner/utils"
import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import RecipePage from "./RecipePage"
import { Book } from "~/models/Book"
import { Category } from "~/models/Category"
import { PartialRecipe } from "~/models/PartialRecipe"
import { Recipe } from "~/models/Recipe"
import { PageContextBase } from "~/types/global-types"

interface PropTypes extends TemplateDefaultPropTypes {
  data: RecipeQuery
  pageContext: PageContextBase
}

function RecipeSSGPage({ data, location, pageContext }: PropTypes) {
  return (
    <>
      <RecipePage
        data={data}
        location={location}
        language={pageContext.language}
        isSSG={true}
      />
    </>
  )
}

interface RecipeQuery {
  recipe: Recipe
  book: Book
  sameCategoryVerticalCarousel: { totalCount: number; nodes: PartialRecipe[] }
  sameCategoryNotVerticalCarousel: {
    totalCount: number
    nodes: PartialRecipe[]
  }
  othersThanCategoryCarousel: { nodes: PartialRecipe[] }
  primaryCategory: Category | null
  alternatesUrl?: {
    nodes: {
      absolute_url: string
      language: string
    }[]
  }
  prismicBanner: PrismicBanners | null
}

//You can find the fragment definition in src/components/Banner/Banner.tsx
export const query = graphql`
  query RecipeSSGPage(
    $id: String
    $language: String
    $prismicLanguage: String
    $primaryCategory: String
    $bookId: String
    $alcoholCategory: String
  ) {
    recipe(id: { eq: $id }) {
      ...Recipe
    }
    book: book(id: { eq: $bookId }) {
      id
      dominant_color
      web_cover_hd
      title
      order_url
    }
    primaryCategory: category(
      categoryId: { eq: $primaryCategory }
      language: { eq: $language }
    ) {
      ...Category
    }
    alcoholCategory: category(
      categoryId: { eq: $alcoholCategory }
      language: { eq: $language }
    ) {
      ...Category
    }
    prismicBanner(
      data: { banner_type: { eq: "recipe_page" } }
      lang: { eq: $prismicLanguage }
    ) {
      ...Banner
    }
  }
`

export default withTemplate(RecipeSSGPage)
